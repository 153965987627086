<template>
  <div id="app">
    <!-- <fullScreenLoading  :isLoading="isLoading"/> -->
    <a-config-provider :locale="locales">
      <router-view />
    </a-config-provider>
  </div>
</template>

<script>
import zh from 'ant-design-vue/lib/locale-provider/zh_CN';
import en from 'ant-design-vue/lib/locale-provider/en_US';
import fullScreenLoading from "@/components/FullScreenLoading/index.vue";
  export default {
    name: 'App',
    data() {
      return {
        locales: window.localStorage.getItem('systemLanguage') == 'zh'? zh : en,
        isLoading: true
      };
    },
   components: {
       fullScreenLoading
    },
    mounted() {
      //切换语言
      if(!window.localStorage.getItem('systemLanguage')){
        this.locales=zh
      }
      setTimeout(() => {
        this.isLoading = false; // 停止加载
      }, 1000); // 2秒后停止加载
    }
  };
</script>

<style>
  html {
    height: 100%;
  }

  body {
    height: 100%;
    margin: 0;
    padding: 0;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    height: 100%;
  }

  .panel .ant-table {
    min-height: 220px !important;
  }
</style>