export default {
  
  name:'system_introduction',
  path:'/',
  redirect:'/system_introduction',
  component:()=> import('@/layouts/system_introduction_layout.vue'),
  children: [
   {
     name:'system_introduction',
     path:'system_introduction',
     component:()=> import('@/views/system_introduction/index.vue')
   },
 ]
 
}